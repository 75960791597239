import React from 'react'
import { CustomDrawer } from 'components/generics/custom-drawer'
import { SearchBarComponent } from '../search-bar/SearchBarComponent'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { DateRangePickerForDrawer } from '../date-range-picker/RangePickerForDrawer'

export const FiltersSideDrawer = ({
  isOpen,
  onClose,
  filtersConfig = {},
  filterProps = {},
  onApplyFilters,
  handleClearFilters,
}) => {
  return (
    <CustomDrawer
      isOpen={isOpen}
      onClose={onClose}
      title="Filter Panel"
      size="sm"
      position="right"
      onApplyFilters={onApplyFilters}
      onClearFilters={handleClearFilters}
    >
      <div className="flex flex-col gap-8">
        {filtersConfig.showFilterDropdown && (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-gray-600"> By Status</p>

            <FilterDropdown
              options={filterProps.filterOptions}
              value={filterProps.selectedFilter}
              onChange={filterProps.onFilterChange}
            />
          </div>
        )}

        {filtersConfig.showDateRangeFilter && (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-gray-600"> By Date Range</p>

            <DateRangePickerForDrawer
              key={`${filterProps.dateRange.date_start}-${filterProps.dateRange.date_end}`}
              dateRange={filterProps.dateRange}
              onDateRangeSubmit={filterProps.onDateRangeSubmit}
              label={filterProps.dateRangeLabel || 'Filter By Created At'}
            />
          </div>
        )}

        {filtersConfig.showSearch && (
          <div className="flex flex-col gap-2">
            <p className="font-semibold text-gray-600">
              Type to Filter Records
            </p>

            <SearchBarComponent
              value={filterProps.searchValue}
              onChange={filterProps.onSearchInputChange}
              onSearch={() => {}}
              inputWrapperClass="border-2 border-gray-500 rounded-lg"
            />
          </div>
        )}
      </div>
    </CustomDrawer>
  )
}
